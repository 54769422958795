import Button from '../../../../components/_shared/Button';
import { ChevronRightIcon } from '../../../../assets/icons';
import { FC } from 'react';
import splashScreenVideo from '../../../../assets/videos/bayer-value-calculator/splash-screen.mp4';
import styles from './SplashScreen.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  onButtonClick: () => void;
}

const SplashScreen: FC<Props> = ({ onButtonClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['splash-screen']}>
      <video src={splashScreenVideo} className={styles['splash-screen__video']} muted autoPlay />
      <h1 className={styles['splash-screen__title']}>
        {t('bayer-rewards-calculator.splash-screen.heading')}
      </h1>
      <p className={styles['splash-screen__subtitle']}>
        {t('bayer-rewards-calculator.splash-screen.subtitle')}
      </p>
      <Button
        onClick={onButtonClick}
        icon={ChevronRightIcon}
        className={styles['splash-screen__button']}
      >
        {t('general.get-started')}
      </Button>
    </div>
  );
};

export default SplashScreen;
