import {
  AddCircleOutline,
  ChevronDownIcon,
  RestoreIcon,
  TrashCanIcon,
} from '../../../../assets/icons';
import { ButtonThemes, IconPosition } from '../../../../lib/constants/components';
import { FC, ReactNode, useEffect, useState } from 'react';
import {
  GET_CALCULATOR_PRODUCTS,
  GET_PRODUCT_QUALIFICATIONS,
} from '../../../../lib/graphql/Calculator.gql';
import { PreFillOption, ProductGroup, SelectedProduct } from '../../../../lib/types/calculator';
import { useLazyQuery, useQuery } from '@apollo/client';

import Accordion from '../../../../components/_shared/Accordion';
import Avatar from '../../../../components/_shared/Avatar';
import Button from '../../../../components/_shared/Button';
import { CalculatorContextActionTypes } from '../../../../lib/contexts/calculator/CalculatorContext.types';
import cx from 'classnames';
import Dropdown from '../../../../components/_shared/Dropdown';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import Spinner from '../../../../components/_shared/Spinner';
import { StepComponentProps } from '../../../../lib/constants/stepper';
import StepsWrapper from '../../../Onboarding/StepsWrapper';
import styles from './ProductSelection.module.scss';
import TextField from '../../../../components/_shared/TextField';
import TotalBreakdown from '../../components/TotalBreakdown';
import { transformProductsByGroup } from '../../../../lib/utils/calculator';
import useCalculatorContext from '../../../../lib/contexts/calculator/useCalculatorContext';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

const ProductSelection: FC<StepComponentProps> = ({ next, back }) => {
  const { t } = useTranslation();
  const [{ preFill, selectedCrops, selectedProducts }, dispatch] = useCalculatorContext();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [groupedProducts, setGroupedProducts] = useState<ProductGroup[]>([]);
  const [getCalculatorProducts, { loading: loadingProducts }] =
    useLazyQuery(GET_CALCULATOR_PRODUCTS);
  const { data: qualifications, loading: loadingQualifications } = useQuery(
    GET_PRODUCT_QUALIFICATIONS
  );
  const [isInputError, setIsInputError] = useState<boolean>(false);

  useEffect(() => {
    if (selectedCrops && preFill) {
      getCalculatorProducts({
        variables: {
          input: {
            prefill: preFill === PreFillOption.YES,
            crops: selectedCrops,
          },
        },
      })
        .then(({ data }) => {
          if (data) {
            setGroupedProducts(transformProductsByGroup(data.calculatorProducts));
          }
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    }
  }, [preFill, selectedCrops, dispatch, getCalculatorProducts, dispatchSnackbar, t]);

  useEffect(() => {
    if (qualifications && !loadingQualifications) {
      dispatch({
        type: CalculatorContextActionTypes.UpdateProductQualifications,
        payload: qualifications,
      });
    }
  }, [qualifications, loadingQualifications, dispatch]);

  useEffect(() => {
    setIsInputError(selectedProducts.some((product) => !!product.sku && !product.acres));
  }, [selectedProducts]);

  const handleAddProduct = (group: string) => {
    dispatch({
      type: CalculatorContextActionTypes.AddSelectedProduct,
      payload: {
        group,
        sku: '',
        name: '',
        crop: '',
        acres: 0,
        pricePerAcre: 0,
      },
    });
  };

  const handleUpdateProduct = (index: number, field: string, value: string | number) => {
    if (field === 'sku') {
      const group = groupedProducts.find((productGroup) =>
        productGroup.options.some((option) => option.value === value)
      );

      const selectedOption = group?.options.find((option) => option.value === value);

      if (selectedOption) {
        dispatch({
          type: CalculatorContextActionTypes.UpdateSelectedProduct,
          payload: {
            index,
            product: {
              sku: value.toString(),
              name: selectedOption.product.name,
              pricePerAcre: selectedOption.product.pricePerAcre,
              crop: selectedOption.product.platform,
            },
          },
        });
      }
    } else {
      dispatch({
        type: CalculatorContextActionTypes.UpdateSelectedProduct,
        payload: {
          index,
          product: { [field]: value },
        },
      });
    }
  };

  const handleRemoveProduct = (index: number) => {
    dispatch({
      type: CalculatorContextActionTypes.RemoveSelectedProduct,
      payload: { index },
    });
  };

  const handleResetCalculator = () => {
    dispatch({
      type: CalculatorContextActionTypes.ResetSelectedProduct,
    });
  };

  const renderAccordionContent = (
    group: ProductGroup,
    product: SelectedProduct,
    index: number
  ): ReactNode => {
    const selectedOption = group.options.find((option) => option.value === product.sku);

    return (
      <div
        className={styles['group-accordion__form']}
        key={product.sku || `${group.group}-${index}`}
      >
        <Dropdown
          label={t('bayer-rewards-calculator.product-selector.product-input')}
          name="product-input"
          options={group.options}
          className={styles['group-accordion__product-input']}
          onChange={(e) => handleUpdateProduct(index, 'sku', e.target.value)}
          menuPlacement="bottom"
          value={selectedOption || undefined}
        />

        <TextField
          inputType="number"
          label={t('bayer-rewards-calculator.product-selector.acres-input')}
          name="acre-input"
          className={cx(styles['form-input'], styles['group-accordion__acre-input'], {
            [styles['form-input--error']]: !product.acres,
          })}
          value={product.acres || ''}
          onChange={(e) =>
            handleUpdateProduct(index, 'acres', parseFloat(e.target.value ? e.target.value : 0))
          }
          hasError={!!product.sku && !product.acres}
          helperText={
            !product.acres ? t('bayer-rewards-calculator.select-products.input-error') : ''
          }
        />

        <Button
          icon={TrashCanIcon}
          theme={ButtonThemes.SECONDARY}
          aria-label={t('bayer-rewards-calculator.product-selector.remove-product')}
          className={styles['group-accordion__remove-product']}
          onClick={() => handleRemoveProduct(index)}
        />
      </div>
    );
  };

  return (
    <StepsWrapper
      title={t('bayer-rewards-calculator.steps.select-products')}
      subTitle={
        <div className={styles['product-selection__subtitle-wrapper']}>
          <h2 className={styles['product-selection__subtitle']}>
            {t('bayer-rewards-calculator.select-products.description')}
          </h2>
          <Button
            theme={ButtonThemes.SECONDARY}
            icon={RestoreIcon}
            onClick={handleResetCalculator}
            disabled={loadingProducts && loadingQualifications}
          >
            {t('bayer-rewards-calculator.select-products.reset-values')}
          </Button>
        </div>
      }
      back={{
        text: t('general.back'),
        disabled: loadingProducts && loadingQualifications,
        onClick: () => {
          scrollTo({ top: 0, behavior: 'smooth' });
          back?.();
        },
      }}
      submit={{
        text: t('general.next'),
        disabled: (loadingProducts && loadingQualifications) || isInputError,
        onClick: () => {
          scrollTo({ top: 0, behavior: 'smooth' });
          next?.();
        },
      }}
      classNames={{
        content: styles['product-selection'],
        wrapper: styles['product-selection__steps-wrapper'],
        form: styles['product-selection__steps-form'],
      }}
    >
      <div className={styles['product-selection__content']}>
        <div className={styles['product-selection__accordion-container']}>
          {loadingProducts && loadingQualifications ? (
            <div className={styles['product-selection__loading']}>
              <Spinner />
            </div>
          ) : (
            <>
              {groupedProducts.map((group, groupIndex) => (
                <div className={styles['group-accordion']} key={group.group}>
                  <Accordion
                    title={
                      <div className={styles['group-accordion__title-wrapper']}>
                        <Avatar firstName={`${groupIndex + 1}`} lastName="" />
                        <div className={styles['group-accordion__text-wrapper']}>
                          <span className={styles['group-accordion__title']}>{group.group}</span>
                        </div>
                      </div>
                    }
                    defaultOpen={
                      selectedProducts
                        .map((product) => ({ ...product }))
                        .filter((product) => product.group === group.group).length > 0
                    }
                    Icon={ChevronDownIcon}
                    classNames={{
                      container: styles['group-accordion__container'],
                      button: styles['group-accordion__button'],
                      innerContainer: styles['group-accordion__inner-container'],
                    }}
                  >
                    {selectedProducts
                      .map((product, fullIndex) => ({ ...product, fullIndex }))
                      .filter((product) => product.group === group.group)
                      .map(({ fullIndex, ...product }) =>
                        renderAccordionContent(group, product, fullIndex)
                      )}

                    <Button
                      icon={AddCircleOutline}
                      theme={ButtonThemes.SECONDARY}
                      iconPosition={IconPosition.LEFT}
                      onClick={() => handleAddProduct(group.group)}
                    >
                      {t('bayer-rewards-calculator.product-selector.add-product')}
                    </Button>
                  </Accordion>
                </div>
              ))}
            </>
          )}
        </div>

        <TotalBreakdown />
      </div>
    </StepsWrapper>
  );
};

ProductSelection.displayName = 'ProductSelection';

export default ProductSelection;
