import { CalculationRule, ProductMainGroup } from '../../../lib/types/calculator';
import { WestCalculatorUrls } from '../../../lib/constants/react-router';

// 2025 BayerValue West

export enum BayerValueWestRuleId {
  SEED_TREATMENT = 'seed-treatment-500',
  EMESTO = 'emesto-500',
  MULTI_SEGMENT = 'multi-segment-bonus',
  FIELD_VIEW = 'fieldview-rebate',
  PRE_BURN_TANK_MIX = 'pre-burn-tank-mix',
}

/**
 * A) Seed Treatment Rewards
 * 500 acres of other eligible Seed Treatment Brands.
 */
const seedTreatmentConditions = {
  group: ProductMainGroup.SEED_TREATMENT,
  minimumAcres: 500,
  rewardPercentage: 0.05,
};

const seedTreatmentRule: CalculationRule = {
  id: BayerValueWestRuleId.SEED_TREATMENT,
  condition: (products) =>
    products.some(
      (product) =>
        product.group === seedTreatmentConditions.group &&
        product.acres >= seedTreatmentConditions.minimumAcres
    ),
  calculate: (products) => {
    const eligibleProducts = products.filter(
      (product) =>
        product.group === seedTreatmentConditions.group &&
        product.acres >= seedTreatmentConditions.minimumAcres
    );
    const reward = eligibleProducts.reduce(
      (sum, product) =>
        sum + product.acres * product.pricePerAcre * seedTreatmentConditions.rewardPercentage,
      0
    );
    return {
      [BayerValueWestRuleId.SEED_TREATMENT]: {
        reward,
        products: eligibleProducts,
        programUrl: WestCalculatorUrls.BAYER_VALUE,
      },
    };
  },
};

/**
 * B) Emesto Bonus
 * Product Eligibility 300 acres of 500 acres of Emesto Silver and/or Emesto Complete
 */
const emestoConditions = {
  minimumAcres: 500,
  eligibleProducts: ['91339670', '84963844', '90063647'],
  rewardPercentage: 0.05,
};

const emestoRule: CalculationRule = {
  id: BayerValueWestRuleId.EMESTO,
  condition: (products) => {
    const totalAcres = products
      .filter((product) => emestoConditions.eligibleProducts.includes(product.sku))
      .reduce((sum, product) => sum + product.acres, 0);

    return totalAcres >= emestoConditions.minimumAcres;
  },
  calculate: (products) => {
    const eligibleProducts = products.filter((product) =>
      emestoConditions.eligibleProducts.includes(product.sku)
    );
    const reward = eligibleProducts.reduce(
      (sum, product) =>
        sum + product.acres * product.pricePerAcre * emestoConditions.rewardPercentage,
      0
    );
    return {
      [BayerValueWestRuleId.EMESTO]: {
        reward,
        products: eligibleProducts,
        programUrl: WestCalculatorUrls.EMESTO,
      },
    };
  },
};

/**
 * D) Segment Savings
 */

const segmentSavingRule: CalculationRule = {
  id: BayerValueWestRuleId.MULTI_SEGMENT,
  condition: (products) => {
    const eligibleSegments = [
      ProductMainGroup.FUNGICIDES,
      ProductMainGroup.HERBICIDES,
      ProductMainGroup.INSECTICIDE,
      ProductMainGroup.SEED_TREATMENT,
    ].filter((group) => {
      const totalAcres = products
        .filter((product) => product.group === group)
        .reduce((sum, product) => sum + product.acres, 0);
      return totalAcres >= 300;
    });

    return eligibleSegments.length >= 2;
  },
  calculate: (products) => {
    const eligibleSegments = [
      ProductMainGroup.FUNGICIDES,
      ProductMainGroup.HERBICIDES,
      ProductMainGroup.INSECTICIDE,
      ProductMainGroup.SEED_TREATMENT,
    ].filter((group) => {
      const totalAcres = products
        .filter((product) => product.group === group)
        .reduce((sum, product) => sum + product.acres, 0);
      return totalAcres >= 300;
    });

    let bonusPercentage = 0;
    if (eligibleSegments.length === 3) {
      bonusPercentage = 0.1;
    } else if (eligibleSegments.length === 2) {
      bonusPercentage = 0.05;
    }

    const eligibleProducts = products.filter((product) =>
      [
        ProductMainGroup.FUNGICIDES,
        ProductMainGroup.HERBICIDES,
        ProductMainGroup.INSECTICIDE,
        ProductMainGroup.SEED_TREATMENT,
      ].includes(product.group as ProductMainGroup)
    );

    const reward = eligibleProducts.reduce(
      (sum, product) => sum + product.acres * product.pricePerAcre * bonusPercentage,
      0
    );

    return {
      [BayerValueWestRuleId.MULTI_SEGMENT]: {
        reward,
        products: eligibleProducts,
        programUrl: WestCalculatorUrls.BAYER_VALUE,
      },
    };
  },
};

/**
 * F) FieldView Rewards
 */
export const fieldViewRebateConditions = {
  minimumCropProtectionSegments: 2,
  minimumAcresCropProtection: 500,
  qualifyingProducts: {
    cropProtectionSegments: [
      ProductMainGroup.FUNGICIDES,
      ProductMainGroup.HERBICIDES,
      ProductMainGroup.INSECTICIDE,
      ProductMainGroup.SEED_TREATMENT,
      ProductMainGroup.TRAIT,
    ],
  },
  rebatePercentage: 0.5,
  subscriptionCost: 500,
};

const fieldViewRebateRule: CalculationRule = {
  id: BayerValueWestRuleId.FIELD_VIEW,
  condition: (products) => {
    const { cropProtectionSegments } = fieldViewRebateConditions.qualifyingProducts;

    const totalCropProtectionAcres = products
      .filter((product) => cropProtectionSegments.includes(product.group as ProductMainGroup))
      .reduce((sum, product) => sum + product.acres, 0);

    const qualifiesForCropProtection =
      totalCropProtectionAcres >= fieldViewRebateConditions.minimumAcresCropProtection &&
      cropProtectionSegments.filter((segment) =>
        products.some((product) => product.group === segment)
      ).length >= fieldViewRebateConditions.minimumCropProtectionSegments;

    return qualifiesForCropProtection;
  },
  calculate: () => {
    const reward =
      fieldViewRebateConditions.subscriptionCost * fieldViewRebateConditions.rebatePercentage;

    return {
      [BayerValueWestRuleId.FIELD_VIEW]: {
        reward,
        products: [],
      },
    };
  },
};

/**
 * G) Pre-burn Tank Mix Bonus Conditions
 * Product Eligibility
 * 300 acres of any combination of:
 * Huskie Pre Herbicide (All SKU's) = 20 acres per smallest saleable unit.
 * Pardner Herbicide (All SKU's) = 20 acres per smallest saleable unit.
 * Olympus Herbicide (All SKU's) = 80 acres per smallest saleable unit.
 *                                  +
 * A minimum of 300 acres of Roundup Transorb HC Herbicide
 */
const preBurnTankMixConditions = {
  combinedProducts: {
    minimunAcres: 300,
    products: [
      {
        name: 'Husky Pre',
        skus: ['89313198', '89316383', '89319145'],
        smallestSaleableUnit: 20,
      },
      {
        name: 'Pardner',
        skus: ['81686343', '4202308'],
        smallestSaleableUnit: 20,
      },
      {
        name: 'Olympus',
        skus: ['85821644'],
        smallestSaleableUnit: 80,
      },
    ],
  },
  mandatoryProduct: {
    minumunAcres: 300,
    products: [
      {
        name: 'RoundUp TransOrb',
        skus: ['10503620', '10543148', '10541856', '11501906'],
      },
    ],
  },
};

const preBurnTankMixRule: CalculationRule = {
  id: BayerValueWestRuleId.PRE_BURN_TANK_MIX,
  condition: (products) => {
    const { combinedProducts, mandatoryProduct } = preBurnTankMixConditions;

    const combinedAcres = combinedProducts.products.reduce(
      (total, productGroup) =>
        total +
        products
          .filter((product) => productGroup.skus.includes(product.sku))
          .reduce((sum, product) => sum + product.acres, 0),
      0
    );

    const mandatoryAcres = products
      .filter((product) => mandatoryProduct.products.some((p) => p.skus.includes(product.sku)))
      .reduce((sum, product) => sum + product.acres, 0);

    return (
      combinedAcres >= combinedProducts.minimunAcres &&
      mandatoryAcres >= mandatoryProduct.minumunAcres
    );
  },
  calculate: (products) => {
    const { combinedProducts, mandatoryProduct } = preBurnTankMixConditions;

    const combinedProductsList = combinedProducts.products.flatMap((productGroup) =>
      products.filter((product) => productGroup.skus.includes(product.sku))
    );

    const mandatoryProductsList = products.filter((product) =>
      mandatoryProduct.products.some((p) => p.skus.includes(product.sku))
    );

    const combinedAcres = combinedProductsList.reduce((sum, product) => sum + product.acres, 0);
    const mandatoryAcres = mandatoryProductsList.reduce((sum, product) => sum + product.acres, 0);

    const rewardAcres = Math.min(combinedAcres, mandatoryAcres);
    const reward = rewardAcres * 1.0;

    return {
      [BayerValueWestRuleId.PRE_BURN_TANK_MIX]: {
        reward,
        products: [...combinedProductsList, ...mandatoryProductsList],
      },
    };
  },
};

export const calculationRules: CalculationRule[] = [
  emestoRule,
  fieldViewRebateRule,
  preBurnTankMixRule,
  seedTreatmentRule,
  segmentSavingRule,
];
