import { CalculationRule, ProductMainGroup, SelectedProduct } from '../../../lib/types/calculator';
import { WestCalculatorUrls } from '../../../lib/constants/react-router';

// 2025 BayerValue West
export enum BayerValueWestRuleId {
  SEED_TREATMENT = 'westSeedTreatmentRewards',
  EMESTO = 'westEmestoBonus',
  SEGMENT_SAVINGS = 'westSegmentSavings',
  FIELD_VIEW = 'fieldview-rebate',
  PRE_BURN_TANK_MIX = 'pre-burn-tank-mix',
}

/**
 * A) Seed Treatment Rewards
 * 500 acres of other eligible Seed Treatment Brands.
 */
const seedTreatmentConditions = {
  group: ProductMainGroup.SEED_TREATMENT,
  qualifyingAcres: 500,
  rewardPercentage: 0.05,
  qualifiers: {
    seedTreatment: 500,
    fungicides: 300,
    herbicides: 300,
  },
};

const seedTreatmentRule: CalculationRule = {
  id: BayerValueWestRuleId.SEED_TREATMENT,
  condition: (products, segmentQualification) => {
    const qualifyingAcres = products.reduce((sum, product) => {
      let multiplier = 0;

      if (segmentQualification.doubleQualifierProducts.includes(product.sku)) {
        multiplier = 2;
      } else if (segmentQualification.qualifierProducts.includes(product.sku)) {
        multiplier = 1;
      }

      if (segmentQualification.segmentRequirement.includes(product.sku) && multiplier > 0) {
        return sum + product.pricePerAcre * product.acres * multiplier;
      }

      return sum;
    }, 0);

    return qualifyingAcres >= seedTreatmentConditions.qualifyingAcres;
  },
  calculate: (products, segmentQualification, qualyfingAcres) => {
    const { herbicides, fungicides, seedTreatment } = qualyfingAcres;
    const { paymentProduct } = segmentQualification;
    const eligibleProducts: SelectedProduct[] = [];
    let totalReward: number = 0;

    products.forEach((product) => {
      if (paymentProduct.includes(product.sku)) {
        switch (product.group) {
          case ProductMainGroup.FUNGICIDES:
            if (fungicides >= seedTreatmentConditions.qualifiers.fungicides) {
              totalReward +=
                product.pricePerAcre * product.acres * seedTreatmentConditions.rewardPercentage;
              eligibleProducts.push(product);
            }
            break;
          case ProductMainGroup.HERBICIDES:
            if (herbicides >= seedTreatmentConditions.qualifiers.herbicides) {
              totalReward +=
                product.pricePerAcre * product.acres * seedTreatmentConditions.rewardPercentage;
              eligibleProducts.push(product);
            }
            break;
          case ProductMainGroup.SEED_TREATMENT:
            if (seedTreatment >= seedTreatmentConditions.qualifiers.seedTreatment) {
              totalReward +=
                product.pricePerAcre * product.acres * seedTreatmentConditions.rewardPercentage;
              eligibleProducts.push(product);
            }
            break;
          default:
            break;
        }
      }
    });

    return {
      [BayerValueWestRuleId.SEED_TREATMENT]: {
        reward: totalReward,
        products: eligibleProducts,
        programUrl: WestCalculatorUrls.BAYER_VALUE,
      },
    };
  },
};

/**
 * B) Emesto Bonus
 * Product Eligibility 300 acres of 500 acres of Emesto Silver and/or Emesto Complete
 */
const emestoConditions = {
  qualifyingAcres: 500,
  rewardPercentage: 0.05,
  qualifiers: {
    fungicides: 300,
  },
};

const emestoRule: CalculationRule = {
  id: BayerValueWestRuleId.EMESTO,
  condition: (products, segmentQualification) => {
    const qualifyingAcres = products.reduce((sum, product) => {
      if (segmentQualification.segmentRequirement.includes(product.sku)) {
        return sum + product.acres;
      }

      return sum;
    }, 0);

    return qualifyingAcres >= emestoConditions.qualifyingAcres;
  },
  calculate: (products, segmentQualification, qualyfingAcres) => {
    const { fungicides } = qualyfingAcres;
    const { paymentProduct } = segmentQualification;
    const eligibleProducts: SelectedProduct[] = [];
    let totalReward: number = 0;

    products.forEach((product) => {
      if (paymentProduct.includes(product.sku)) {
        const isFungicide = product.group === ProductMainGroup.FUNGICIDES;
        const qualifiesForFungicideReward =
          isFungicide && fungicides >= emestoConditions.qualifiers.fungicides;

        if (qualifiesForFungicideReward || !isFungicide) {
          totalReward += product.acres * product.pricePerAcre * emestoConditions.rewardPercentage;
          eligibleProducts.push(product);
        }
      }
    });

    return {
      [BayerValueWestRuleId.EMESTO]: {
        reward: totalReward,
        products: eligibleProducts,
        programUrl: WestCalculatorUrls.EMESTO,
      },
    };
  },
};

/**
 * D) Segment Savings
 */

const segmentSavingConditions = {
  qualifyingAcres: 300,
  qualifyingSegments: 2,
  rewardPercentageTwoSegment: 0.05,
  rewardPercentageThreeSegment: 0.1,
};

const segmentSavingRule: CalculationRule = {
  id: BayerValueWestRuleId.SEGMENT_SAVINGS,
  condition: (_, __, qualifyingAcres) => {
    const { herbicides, fungicides, seedTreatment, insecticides } = qualifyingAcres;
    const segmentList = [herbicides, fungicides, seedTreatment + insecticides];

    const qualifyingSegments = segmentList.reduce((sum, acres) => {
      if (acres >= segmentSavingConditions.qualifyingAcres) {
        return sum + 1;
      }

      return sum;
    }, 0);

    return qualifyingSegments >= segmentSavingConditions.qualifyingSegments;
  },
  calculate: (products, segmentQualification, qualifyingAcres) => {
    const { herbicides, fungicides, seedTreatment, insecticides } = qualifyingAcres;
    const { paymentProduct } = segmentQualification;
    const segmentList = [herbicides, fungicides, seedTreatment + insecticides];
    const eligibleProducts: SelectedProduct[] = [];
    let totalReward: number = 0;

    const qualifyingSegments = segmentList.reduce((sum, acres) => {
      if (acres >= segmentSavingConditions.qualifyingAcres) {
        return sum + 1;
      }

      return sum;
    }, 0);

    let bonusPercentage = 0;

    if (qualifyingSegments === 3) {
      bonusPercentage = segmentSavingConditions.rewardPercentageThreeSegment;
    } else if (qualifyingSegments === 2) {
      bonusPercentage = segmentSavingConditions.rewardPercentageTwoSegment;
    }

    const groupConditions: Record<ProductMainGroup, () => boolean> = {
      [ProductMainGroup.FUNGICIDES]: () => fungicides >= segmentSavingConditions.qualifyingAcres,
      [ProductMainGroup.HERBICIDES]: () => herbicides >= segmentSavingConditions.qualifyingAcres,
      [ProductMainGroup.INSECTICIDE]: () =>
        insecticides + seedTreatment >= segmentSavingConditions.qualifyingAcres,
      [ProductMainGroup.SEED_TREATMENT]: () =>
        seedTreatment + seedTreatment >= segmentSavingConditions.qualifyingAcres,
      [ProductMainGroup.TRAIT]: () => false,
    };

    products.forEach((product) => {
      const condition = groupConditions[product.group as ProductMainGroup];
      if (condition?.() && paymentProduct.includes(product.sku)) {
        totalReward += product.acres * product.pricePerAcre * bonusPercentage;
        eligibleProducts.push(product);
      }
    });

    return {
      [BayerValueWestRuleId.SEGMENT_SAVINGS]: {
        reward: totalReward,
        products: eligibleProducts,
        programUrl: WestCalculatorUrls.BAYER_VALUE,
      },
    };
  },
};

/**
 * F) FieldView Rewards
 */
export const fieldViewRebateConditions = {
  minimumCropProtectionSegments: 2,
  minimumAcresCropProtection: 500,
  qualifyingProducts: {
    cropProtectionSegments: [
      ProductMainGroup.FUNGICIDES,
      ProductMainGroup.HERBICIDES,
      ProductMainGroup.INSECTICIDE,
      ProductMainGroup.SEED_TREATMENT,
      ProductMainGroup.TRAIT,
    ],
  },
  rebatePercentage: 0.5,
  subscriptionCost: 500,
};

const fieldViewRebateRule: CalculationRule = {
  id: BayerValueWestRuleId.FIELD_VIEW,
  condition: (products) => {
    const { cropProtectionSegments } = fieldViewRebateConditions.qualifyingProducts;

    const totalCropProtectionAcres = products
      .filter((product) => cropProtectionSegments.includes(product.group as ProductMainGroup))
      .reduce((sum, product) => sum + product.acres, 0);

    const qualifiesForCropProtection =
      totalCropProtectionAcres >= fieldViewRebateConditions.minimumAcresCropProtection &&
      cropProtectionSegments.filter((segment) =>
        products.some((product) => product.group === segment)
      ).length >= fieldViewRebateConditions.minimumCropProtectionSegments;

    return qualifiesForCropProtection;
  },
  calculate: () => {
    const reward =
      fieldViewRebateConditions.subscriptionCost * fieldViewRebateConditions.rebatePercentage;

    return {
      [BayerValueWestRuleId.FIELD_VIEW]: {
        reward,
        products: [],
      },
    };
  },
};

/**
 * G) Pre-burn Tank Mix Bonus Conditions
 * Product Eligibility
 * 300 acres of any combination of:
 * Huskie Pre Herbicide (All SKU's) = 20 acres per smallest saleable unit.
 * Pardner Herbicide (All SKU's) = 20 acres per smallest saleable unit.
 * Olympus Herbicide (All SKU's) = 80 acres per smallest saleable unit.
 *                                  +
 * A minimum of 300 acres of Roundup Transorb HC Herbicide
 */
const preBurnTankMixConditions = {
  combinedProducts: {
    minimunAcres: 300,
    products: [
      {
        name: 'Husky Pre',
        skus: ['89313198', '89316383', '89319145'],
        smallestSaleableUnit: 20,
      },
      {
        name: 'Pardner',
        skus: ['81686343', '4202308'],
        smallestSaleableUnit: 20,
      },
      {
        name: 'Olympus',
        skus: ['85821644'],
        smallestSaleableUnit: 80,
      },
    ],
  },
  mandatoryProduct: {
    minumunAcres: 300,
    products: [
      {
        name: 'RoundUp TransOrb',
        skus: ['10503620', '10543148', '10541856', '11501906'],
      },
    ],
  },
};

const preBurnTankMixRule: CalculationRule = {
  id: BayerValueWestRuleId.PRE_BURN_TANK_MIX,
  condition: (products) => {
    const { combinedProducts, mandatoryProduct } = preBurnTankMixConditions;

    const combinedAcres = combinedProducts.products.reduce(
      (total, productGroup) =>
        total +
        products
          .filter((product) => productGroup.skus.includes(product.sku))
          .reduce((sum, product) => sum + product.acres, 0),
      0
    );

    const mandatoryAcres = products
      .filter((product) => mandatoryProduct.products.some((p) => p.skus.includes(product.sku)))
      .reduce((sum, product) => sum + product.acres, 0);

    return (
      combinedAcres >= combinedProducts.minimunAcres &&
      mandatoryAcres >= mandatoryProduct.minumunAcres
    );
  },
  calculate: (products) => {
    const { combinedProducts, mandatoryProduct } = preBurnTankMixConditions;

    const combinedProductsList = combinedProducts.products.flatMap((productGroup) =>
      products.filter((product) => productGroup.skus.includes(product.sku))
    );

    const mandatoryProductsList = products.filter((product) =>
      mandatoryProduct.products.some((p) => p.skus.includes(product.sku))
    );

    const combinedAcres = combinedProductsList.reduce((sum, product) => sum + product.acres, 0);
    const mandatoryAcres = mandatoryProductsList.reduce((sum, product) => sum + product.acres, 0);

    const rewardAcres = Math.min(combinedAcres, mandatoryAcres);
    const reward = rewardAcres * 1.0;

    return {
      [BayerValueWestRuleId.PRE_BURN_TANK_MIX]: {
        reward,
        products: [...combinedProductsList, ...mandatoryProductsList],
      },
    };
  },
};

export const calculationRules: CalculationRule[] = [
  emestoRule,
  fieldViewRebateRule,
  preBurnTankMixRule,
  seedTreatmentRule,
  segmentSavingRule,
];
