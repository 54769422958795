import React, { FC } from 'react';

import Accordion from '../../../../components/_shared/Accordion';
import { ChevronDownIcon } from '../../../../assets/icons';
import cx from 'classnames';
import styles from './TotalBreakdown.module.scss';
import useCalculatorContext from '../../../../lib/contexts/calculator/useCalculatorContext';
import { useTranslation } from 'react-i18next';

const TotalBreakdown: FC = () => {
  const { t } = useTranslation();
  const [{ selectedProducts, totalCost, totalRewards }] = useCalculatorContext();

  return (
    <div className={styles['total-breakdown']}>
      <div className={styles['total-breakdown__running-total-wrapper']}>
        <span className={styles['total-breakdown__running-total-title']}>
          {t('bayer-rewards-calculator.total-amount-breakdown.estimated-rewards')}
        </span>
        <span className={styles['total-breakdown__running-total-amount']}>
          {t('format.price', { value: totalRewards })}
        </span>
      </div>
      <Accordion
        title={t('bayer-rewards-calculator.total-amount-breakdown.title')}
        defaultOpen
        Icon={ChevronDownIcon}
        classNames={{
          container: styles['total-breakdown__accordion-container'],
          button: styles['total-breakdown__accordion-button'],
        }}
      >
        <dl className={styles['total-breakdown__product-list']}>
          {selectedProducts
            .filter((product) => !!product.sku)
            .map((product) => (
              <React.Fragment key={product.sku}>
                <dt className={styles['total-breakdown__product-title']}>{product.name}</dt>
                <dd className={styles['total-breakdown__product-amount']}>
                  {!!product.pricePerAcre &&
                    t('format.price', { value: product.acres * product.pricePerAcre })}
                </dd>
              </React.Fragment>
            ))}
          <dt
            className={cx(
              styles['total-breakdown__product-title'],
              styles['total-breakdown__product-title--total']
            )}
          >
            {t('bayer-rewards-calculator.total-amount-breakdown.running-total')}
          </dt>
          <dd
            className={cx(
              styles['total-breakdown__product-amount'],
              styles['total-breakdown__product-amount--total']
            )}
          >
            {t('format.price', {
              value: totalCost,
            })}
          </dd>
        </dl>
      </Accordion>
    </div>
  );
};

TotalBreakdown.displayName = 'TotalBreakdown';

export default TotalBreakdown;
