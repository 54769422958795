import { BayerValueEastProvinces } from '../constants/forms';
import { GET_FARM_PROVINCE } from '../graphql/FarmInformation.gql';
import { GET_PROGRAM_REWARDS_BREAKDOWN_QUERY } from '../graphql/ProgramRewards.gql';
import { Region } from '../constants/pre-orders';
import { useQuery } from '@apollo/client';

export const usePreferredRegion = () => {
  const { data: programData, loading: programDataLoading } = useQuery(
    GET_PROGRAM_REWARDS_BREAKDOWN_QUERY,
    {
      variables: { input: { year: new Date().getFullYear() } },
    }
  );
  const { data: provinceData, loading: provinceDataLoading } = useQuery(GET_FARM_PROVINCE);
  const normalizedProgram =
    programData?.enrolledProgramsInfo?.bayerValueProgramInfo?.program.name.toLocaleLowerCase();
  let programRegion;

  if (normalizedProgram?.includes(Region.EAST)) {
    programRegion = Region.EAST;
  } else if (normalizedProgram?.includes(Region.WEST)) {
    programRegion = Region.WEST;
  } else {
    programRegion =
      provinceData?.farm.farmInfo.address?.province &&
      BayerValueEastProvinces.includes(provinceData.farm.farmInfo.address.province)
        ? Region.EAST
        : Region.WEST;
  }

  return { programRegion, isLoading: programDataLoading || provinceDataLoading };
};
