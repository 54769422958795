import { cacheFirstRoutes, PageRoutes } from '../../lib/constants/react-router';
import { DataLayerEvent, pushDataLayerEvent } from '../../lib/utils/data-layer';
import { FC, useEffect, useState } from 'react';
import { GET_FARM_INFO_NAV, GET_USER_INFO_NAV } from '../../lib/graphql/Navigation.gql';
import { QueryHookOptions, useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import cx from 'classnames';
import { Exact } from '../../__generated__/graphql';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import styles from './Header.module.scss';
import { useBreakpoint } from '../../lib/hooks';
import { useLocation } from 'react-router-dom';

export interface Props {
  className?: string;
}

const bayerIconPaths = [
  PageRoutes.LOGIN,
  PageRoutes.LOGIN_ERROR,
  PageRoutes.SIGNUP,
  PageRoutes.ONBOARDING,
  PageRoutes.RESET_PASSWORD_CONFIRMATION,
  PageRoutes.EMAIL_VERIFIED,
];

const Header: FC<Props> = ({ className }) => {
  const { isLg } = useBreakpoint();
  const { pathname } = useLocation();
  const [fetchPolicy, setFetchPolicy] = useState<WatchQueryFetchPolicy>('cache-only');
  const [displayLogo, setDisplayLogo] = useState<boolean>(false);

  const queryOptions: QueryHookOptions<any, Exact<{ [key: string]: never }>> = {
    fetchPolicy,
    partialRefetch: true,
    returnPartialData: true,
  };
  const { data: user } = useQuery(GET_USER_INFO_NAV, queryOptions);
  const { data: farm } = useQuery(GET_FARM_INFO_NAV, queryOptions);

  useEffect(() => {
    if (user) {
      pushDataLayerEvent(DataLayerEvent.USER_AUTHENTICATED, { userId: user.user.accountInfo.id });
    }
  }, [user]);

  useEffect(() => {
    if (cacheFirstRoutes.some((path) => pathname.includes(path)) || pathname === PageRoutes.HOME) {
      setFetchPolicy('cache-first');
    } else {
      setFetchPolicy('cache-only');
    }
    if (bayerIconPaths.every((path) => !pathname.includes(path))) {
      setDisplayLogo(false);
    } else {
      setDisplayLogo(true);
    }
  }, [pathname]);

  return (
    <header className={cx(className, styles['header'])}>
      {isLg ? (
        <HeaderMobile
          user={user?.user.accountInfo}
          farm={farm?.farm.farmInfo}
          displayLogo={displayLogo}
        />
      ) : (
        <HeaderDesktop
          user={user?.user.accountInfo}
          farm={farm?.farm.farmInfo}
          displayLogo={displayLogo}
        />
      )}
    </header>
  );
};

Header.displayName = 'Header';

export default Header;
