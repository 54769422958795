import { gql } from '../../__generated__';

export const GET_PROGRAM_REWARDS_BREAKDOWN_QUERY = gql(`
query ProgramRewards($input: EnrolledProgramsInfoInput!) {
  enrolledProgramsInfo(input: $input) {
    bayerValueProgramInfo {
      program {
        id
        name
        type
        effectiveFrom
        effectiveTo
      }
      signUpDate
      rewardAmountInDollars
    }
    otherPrograms {
      program {
        id
        name
        type
        effectiveFrom
        effectiveTo
      }
      signUpDate
      rewardAmountInDollars
    }
    totalRewardAmountInDollars
  }
}`);

export const GET_TOTAL_REWARDS_EARNED_QUERY = gql(`
query TotalProgramRewardsEarned($input: EnrolledProgramsInfoInput!) {
  enrolledProgramsInfo(input: $input) {
    bayerValueProgramInfo {
      program {
        id
        name
      }
      rewardAmountInDollars
    }
    totalRewardAmountInDollars
  }
}`);

export const GET_TOTAL_PURCHASES_REWARDS = gql(`
query PurchaseTotalInDollars($input: EnrolledProgramsInfoInput!, $input2: PurchaseTotalInDollarsInput!) {
  enrolledProgramsInfo(input: $input) {
    totalRewardAmountInDollars
  }
  purchaseTotalInDollars(input: $input2) {
    purchaseTotal
  }
}
`);

export const GET_BAYER_VALUE_PROGRAM_QUERY = gql(`
query FarmBayerValueProgram($input: EnrolledProgramsInfoInput!) {
  enrolledProgramsInfo(input: $input) {
    bayerValueProgramInfo {
      program {
        id
        name
        type
        year
      }
      signUpDate
    }
  }
}`);

export const GET_BAYER_VALUE_RETREAT_INFO = gql(`
query BayerValueRetreatEnrollment($input: BayerValueRetreatEnrollmentInput) {
  bayerValueRetreatEnrollment(input: $input) {
    bayerValueRetreatEnrollment {
      id
      initialCost
      budgetRemaining
      rebateAmount
      signUpDate
      numberOfPeople
      program {
        name
        effectiveFrom
        multiYear
      }
    }
  }
}`);

export const GET_BAYER_VALUE_PROGRAM = gql(`
query GetBayerValueProgram($input: EnrolledProgramsInfoInput!) {
  enrolledProgramsInfo(input: $input) {
    bayerValueProgramInfo {
      program {
        id
        name
        effectiveFrom
        effectiveTo
      }
    }
  }
}`);

export const GET_BAYER_VALUE_PROGRAM_BY_ID = gql(`
query GetBayerValueProgramInfo($input: BayerValueProgramInfoInput!) {
  bayerValueProgramInfo(input: $input) {
    program {
      id
      name
      year
      effectiveFrom
      effectiveTo
    }
    programRewardMetrics {
      id
      acres
      category
      eligibleProducts
      eligiblePurchases
      qualifyingAcres
      qualified
      rewardLevel
      segment
    }
    rewardAmountInDollars
  }
}`);

export const GET_BAYER_VALUE_SEGMENTS_QUERY = gql(`
query EnrolledProgramsInfo($input: EnrolledProgramsInfoInput!) {
  enrolledProgramsInfo(input: $input) {
    bayerValueProgramInfo {
      program {
        id
        name
      }
      programRewardMetrics {
        id
        acres
        category
        eligibleProducts
        eligiblePurchases
        qualified
        qualifyingAcres
        segment
        rewardLevel
      }
    }
  }
}`);
