export enum DataLayerEvent {
  USER_AUTHENTICATED = 'userAuthenticated',
  LANGUAGE = 'userLanguage',
}

/**
 * Pushes an event with its associated payload to the GTM data layer.
 *
 * @param event - A string representing the event.
 * @param payload - An object containing additional data to be pushed with the event.
 */
export const pushDataLayerEvent = (event: string, payload: Record<string, any>): void => {
  window.dataLayer = window.dataLayer || [];

  const index = window.dataLayer.findIndex((entry: any) => entry.event === event);

  if (index !== -1) {
    window.dataLayer[index] = { event, ...payload };
  } else {
    window.dataLayer.push({ event, ...payload });
  }
};
