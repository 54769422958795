import { gql } from '../../__generated__';

export const GET_CALCULATOR_PRODUCTS = gql(`
  query CalculatorProducts($input: CalculatorProductsInput!) {
    calculatorProducts(input: $input) {
      group
      products {
        name
        sku
        pricePerAcre
        mainGroup
        platform
      }
    }
  }`
);

export const GET_PRODUCT_QUALIFICATIONS = gql(`
  query ProductQualifications {
    productQualifications {
      doubleQualifierProducts
      paymentProduct
      qualifierProducts
      segmentRequirement
      segment
    }
  }`
);
