import { ButtonThemes, IconPosition } from '../../../lib/constants/components';
import { ChevronLeftIcon, RestoreIcon } from '../../../assets/icons';
import React, { FC, ReactNode } from 'react';
import StepsWrapperFooter, { CTA } from './StepsWrapperFooter';
import Button from '../../../components/_shared/Button';
import cx from 'classnames';
import SettingsSkeleton from '../../../components/_shared/SettingsSkeleton';
import styles from './StepsWrapper.module.scss';

export interface Props {
  title: string;
  subTitle?: string | ReactNode;
  children: ReactNode;
  back?: CTA;
  submit?: CTA;
  classNames?: {
    content?: string;
    wrapper?: string;
    container?: string;
    form?: string;
  };
  loadingQuery?: boolean;
  hideFooter?: boolean;
  actionsOnHeader?: boolean;
}

const StepsWrapper: FC<Props> = ({
  title,
  subTitle,
  children,
  back,
  submit,
  classNames,
  loadingQuery,
  hideFooter,
  actionsOnHeader,
}) => (
  <div className={cx(styles['steps-wrapper'], classNames?.wrapper)}>
    <div className={cx(styles['steps-wrapper__container'], classNames?.container)}>
      <div className={cx(styles['steps-wrapper__form'], classNames?.form)}>
        {loadingQuery ? (
          <SettingsSkeleton />
        ) : (
          <>
            <div className={cx(styles['steps-wrapper__header'])}>
              {actionsOnHeader && (
                <div className={cx(styles['steps-wrapper__header-actions'])}>
                  <Button
                    type={back?.buttonType || 'button'}
                    formID={back?.buttonFormId}
                    theme={ButtonThemes.PRIMARY}
                    icon={ChevronLeftIcon}
                    iconPosition={IconPosition.LEFT}
                    disabled={back?.disabled}
                    onClick={back?.onClick}
                  >
                    {back?.text}
                  </Button>
                  <Button
                    type={submit?.buttonType || 'button'}
                    formID={submit?.buttonFormId}
                    theme={ButtonThemes.SECONDARY}
                    icon={RestoreIcon}
                    iconPosition={IconPosition.LEFT}
                    disabled={submit?.disabled}
                    onClick={submit?.onClick}
                  >
                    {submit?.text}
                  </Button>
                </div>
              )}
              <h1 className={cx(styles['steps-wrapper__title'])}>{title}</h1>
              {subTitle &&
                (React.isValidElement(subTitle) ? (
                  <div className={cx(styles['steps-wrapper__sub-title-block'])}>{subTitle}</div>
                ) : (
                  <p className={cx(styles['steps-wrapper__sub-title'])}>{subTitle}</p>
                ))}
            </div>
            <div className={cx(styles['steps-wrapper__content'], classNames?.content)}>
              {children}
            </div>
          </>
        )}
      </div>
    </div>
    {!hideFooter && <StepsWrapperFooter secondaryCta={back} primaryCta={submit} />}
  </div>
);

StepsWrapper.displayName = 'StepsWrapper';

export default StepsWrapper;
