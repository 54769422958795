/* eslint-disable jsx-a11y/anchor-is-valid */
import { CookieConfig, Cookies, Mode, REDIRECT_URL_SS_KEY } from '../../../lib/constants/gigya';
import { FC, useEffect, useRef } from 'react';
import { PageRoutes, QueryParams } from '../../../lib/constants/react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';

import axios from 'axios';
import cookies from 'js-cookie';
import cx from 'classnames';
import { Endpoints } from '../../../lib/constants/endpoints';
import { firstLoginHandler } from '../../../lib/utils/gigya';
import image from '../../../assets/images/login-landscape.jpg';
import ImageWithContent from '../../../components/ImageWithContent';
import { LogoutUser } from '../../../lib/clients/gigya-client';
import PreAuthContainer from '../../../components/PreAuthContainer';
import styles from '../Login.module.scss';
import { useGigya } from '../../../lib/hooks';
import { usePageTitle } from '../../../lib/hooks/usePageTitle';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

const GigyaLogin: FC = () => {
  usePageTitle('gigya-login');
  const gigyaLoaded = useGigya();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const containerId = useRef<string>('login-gigya');
  const [searchParams] = useSearchParams();
  const code = searchParams.get(QueryParams.GIGYA_CODE);

  useEffect(() => {
    if (gigyaLoaded) {
      const url = window.gigya.utils.URL.addParamsToURL(PageRoutes.GIGYA_PROXY_PAGE, {
        mode: Mode.AFTER_LOGIN,
      });

      window.gigya.accounts.showScreenSet({
        screenSet: 'bayer-RegistrationLogin',
        startScreen: 'bayer-login-nosc',
        containerID: containerId.current,
        sessionExpiration: process.env.REACT_APP_GIGYA_LOGIN_SESSION_TIME,
        authflow: 'redirect',
        redirectURL: url,
        lang: language,
      });

      if (code) {
        const authCode = encodeURIComponent(code);
        axios
          .post(
            Endpoints.TOKEN,
            {},
            {
              withCredentials: true,
              params: {
                code: authCode,
                grantType: 'authorization_code',
              },
              headers: {
                'x-request-id': uuid(),
              },
            }
          )
          .then((res: any) => {
            cookies.set(Cookies.CS_GROWERS_TOKEN_EXPIRATION, res.data.cookieExpiration, {
              ...CookieConfig(res.data.cookieExpiration),
            });
            const redirectUrl = sessionStorage.getItem(REDIRECT_URL_SS_KEY);

            if (redirectUrl) {
              sessionStorage.removeItem(REDIRECT_URL_SS_KEY);
              navigate(redirectUrl);
            } else {
              navigate(PageRoutes.HOME);
            }
          })
          .catch((error) => {
            if (error?.response?.status === 403) {
              LogoutUser(false, PageRoutes.LOGIN_ERROR);
            } else {
              LogoutUser();
            }
          });
      }
    }
  }, [gigyaLoaded, code, language, navigate]);

  useEffect(() => {
    if (gigyaLoaded) {
      window.gigya.accounts.addEventHandlers({
        onLogin: firstLoginHandler,
      });
    }
  }, [gigyaLoaded]);

  return (
    <PreAuthContainer>
      <ImageWithContent imageSrc={image} imageAlt={t('images.farmers')}>
        <div className={cx(styles['login-container'])}>
          <div id={containerId.current}></div>
        </div>
      </ImageWithContent>
    </PreAuthContainer>
  );
};

GigyaLogin.displayName = 'GigyaLogin';

export default GigyaLogin;
