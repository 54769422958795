import { CalculatorStep, type StepComponentProps } from '../../../../lib/constants/stepper';
import { CheckmarkIcon, FileDownloadIcon, PrintIcon } from '../../../../assets/icons';
import { FC, ReactNode } from 'react';
import {
  ProductMainGroup,
  ResultsChartColor,
  RewardsDetails,
} from '../../../../lib/types/calculator';

import { BayerValueWestRuleId } from '../../rules/west';
import Button from '../../../../components/_shared/Button';
import { ButtonThemes } from '../../../../lib/constants/components';
import cx from 'classnames';
import InfoBanner from '../../../../components/_shared/InfoBanner';
import Link from '../../../../components/_shared/Link';
import ProgramDoughnutChart from '../../../../components/_shared/ProgramDoughnutChart';
import StepsWrapper from '../../../Onboarding/StepsWrapper';
import styles from './Results.module.scss';
import { useBreakpoint } from '../../../../lib/hooks';
import useCalculatorContext from '../../../../lib/contexts/calculator/useCalculatorContext';
import { useTranslation } from 'react-i18next';

const Results: FC<StepComponentProps> = ({ back, goToStep }) => {
  const { t } = useTranslation();
  const [{ selectedProducts, breakdown, totalRewards, totalCost }] = useCalculatorContext();
  const { isXs } = useBreakpoint();

  const renderTableComponent = (rewardKey: string, details: RewardsDetails): ReactNode => (
    <table className={styles['table']}>
      <thead className={cx({ 'sr-only': isXs })}>
        <tr>
          <th className={cx(styles['table__header'])}>
            {t('bayer-rewards-calculator.results.breakdown-table.header.category')}
          </th>
          <th className={cx(styles['table__header'])}>
            {t('bayer-rewards-calculator.results.breakdown-table.header.product')}
          </th>
          <th className={cx(styles['table__header'])}>
            {t('bayer-rewards-calculator.results.breakdown-table.header.crop')}
          </th>
          <th className={cx(styles['table__header'])}>
            {t('bayer-rewards-calculator.results.breakdown-table.header.acres')}
          </th>
        </tr>
      </thead>
      <tbody className={cx(styles['table__tbody'])}>
        {details.products.map((product) => (
          <tr key={`${rewardKey}-${product.sku}`} className={cx(styles['table__data-row'])}>
            <td
              className={styles['table__data-cell']}
              data-label={t('bayer-rewards-calculator.results.breakdown-table.header.category')}
            >
              {product.group}
            </td>
            <td
              className={styles['table__data-cell']}
              data-label={t('bayer-rewards-calculator.results.breakdown-table.header.product')}
            >
              {product.name}
            </td>
            <td
              className={styles['table__data-cell']}
              data-label={t('bayer-rewards-calculator.results.breakdown-table.header.crop')}
            >
              {product.crop}
            </td>
            <td
              className={styles['table__data-cell']}
              data-label={t('bayer-rewards-calculator.results.breakdown-table.header.acres')}
            >
              {product.acres}
            </td>
          </tr>
        ))}
        <tr className={styles['table__data-row']}>
          <td
            colSpan={3}
            className={cx(styles['table__data-cell'], styles['table__bottom-header'])}
          >
            {t('bayer-rewards-calculator.results.breakdown-table.total-rewards')}
          </td>
          <td className={cx(styles['table__data-cell'], styles['table__bottom-header'])}>
            {t('format.price', {
              value: details.reward,
            })}
          </td>
        </tr>
      </tbody>
    </table>
  );

  const renderRewardComponent = (rewardKey: string, details: RewardsDetails): ReactNode => {
    if (rewardKey === BayerValueWestRuleId.FIELD_VIEW) {
      return (
        <div>
          <InfoBanner
            title={t('bayer-rewards-calculator.results.special-conditions.field-view.header')}
            className={cx(styles['farm-information__banner'])}
            closeButton={false}
            icon={CheckmarkIcon}
            content={t('bayer-rewards-calculator.results.special-conditions.field-view.content')}
          />

          <dl className={styles['description-list']}>
            <dt className={cx(styles['description-list__label'])}>
              {t('bayer-rewards-calculator.results.breakdown-table.total-rewards')}
            </dt>
            <dd className={cx(styles['description-list__value'])}>
              {t('format.price', {
                value: details.reward,
              })}
            </dd>
          </dl>
        </div>
      );
    }

    return renderTableComponent(rewardKey, details);
  };

  const renderRewardSection = (rewardKey: string, details: RewardsDetails): ReactNode => (
    <div key={rewardKey} className={styles['results__table-container']}>
      <div className={styles['results__table-title-wrapper']}>
        {details.programUrl ? (
          <Link
            href={details.programUrl}
            target="_blank"
            altText={t('bayer-value.climate-fieldview.cta-alt')}
            rel="noopener noreferrer"
            className={styles['results__table-title']}
          >
            {t(`bayer-rewards-calculator.results.component.${rewardKey}`)}
          </Link>
        ) : (
          <span className={styles['results__table-title']}>
            {t(`bayer-rewards-calculator.results.component.${rewardKey}`)}
          </span>
        )}
      </div>

      {renderRewardComponent(rewardKey, details)}
    </div>
  );

  return (
    <StepsWrapper
      title={t('bayer-rewards-calculator.steps.results')}
      back={{ text: t('bayer-rewards-calculator.results.back-to-calculator'), onClick: back }}
      submit={{
        text: t('bayer-rewards-calculator.results.new-calculation'),
        onClick: () => goToStep?.(CalculatorStep.PREFERENCES),
      }}
      hideFooter
      actionsOnHeader
      classNames={{
        wrapper: styles['results__steps-wrapper'],
        form: styles['results__steps-form'],
      }}
    >
      <div className={styles['results']}>
        {breakdown && (
          <div className={cx(styles['results__chart'])}>
            <div className={cx(styles['results__chart-legend'])}>
              <div className={cx(styles['results__chart-title'])}>
                <span className={cx(styles['results__chart-title-text'])}>
                  {t('bayer-rewards-calculator.results.chart.title')}
                </span>
                <span className={cx(styles['results__chart-title-amount'])}>
                  {t('format.price', { value: totalRewards })}
                </span>
              </div>
              <span className={cx(styles['results__chart-legend-title'])}>
                {t('bayer-rewards-calculator.results.chart.legend-title')}
              </span>
              {Object.keys(breakdown).map(
                (rewardKey, index) =>
                  rewardKey !== ProductMainGroup.TRAIT && (
                    <div className={cx(styles['results__chart-legend-item'])}>
                      <div
                        aria-hidden
                        className={cx(styles['results__chart-legend-indicator'])}
                        style={{ backgroundColor: ResultsChartColor[index] }}
                      />
                      <span className={cx(styles['results__chart-legend-text'])}>
                        {t(`bayer-rewards-calculator.results.component.${rewardKey}`)}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className={cx(styles['results__chart-container'])}>
              <div className={cx(styles['results__chart-wrapper'])}>
                <ProgramDoughnutChart
                  data={Object.entries(breakdown).map(([rewardKey, details], index) => ({
                    color: ResultsChartColor[index],
                    amount: details.reward,
                    programName: t(`bayer-rewards-calculator.results.component.${rewardKey}`),
                  }))}
                  label={t('bayer-value.breakdown.rewards-earned')}
                />
              </div>
            </div>
          </div>
        )}

        <div className={styles['results__table-container']}>
          <div className={styles['results__table-title-wrapper']}>
            <span className={styles['results__table-title']}>
              {t('bayer-rewards-calculator.results.component.products-included')}
            </span>
            <div className={styles['results__table-actions']}>
              <Button theme={ButtonThemes.TEXT_LINK} icon={PrintIcon}>
                {t('bayer-rewards-calculator.results.print')}
              </Button>
              <Button theme={ButtonThemes.TEXT_LINK} icon={FileDownloadIcon}>
                {t('bayer-rewards-calculator.results.download')}
              </Button>
            </div>
          </div>
          <table className={styles['table']}>
            <thead className={cx({ 'sr-only': isXs })}>
              <tr>
                <th className={cx(styles['table__header'])}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.category')}
                </th>
                <th className={cx(styles['table__header'])}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.product')}
                </th>
                <th className={cx(styles['table__header'])}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.crop')}
                </th>
                <th className={cx(styles['table__header'])}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.acres')}
                </th>
                <th className={cx(styles['table__header'])}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.estimated-cost')}
                </th>
              </tr>
            </thead>
            <tbody className={cx(styles['table__tbody'])}>
              {selectedProducts
                .filter((product) => (product.pricePerAcre ?? 0) > 0)
                .map((selectedProduct) => (
                  <tr
                    key={`total-breakdown-${selectedProduct.sku}`}
                    className={cx(styles['table__data-row'])}
                  >
                    <td
                      className={styles['table__data-cell']}
                      data-label={t(
                        'bayer-rewards-calculator.results.breakdown-table.header.category'
                      )}
                    >
                      {selectedProduct.group}
                    </td>
                    <td
                      className={styles['table__data-cell']}
                      data-label={t(
                        'bayer-rewards-calculator.results.breakdown-table.header.product'
                      )}
                    >
                      {selectedProduct.name}
                    </td>
                    <td
                      className={styles['table__data-cell']}
                      data-label={t('bayer-rewards-calculator.results.breakdown-table.header.crop')}
                    >
                      {selectedProduct.crop}
                    </td>
                    <td
                      className={styles['table__data-cell']}
                      data-label={t(
                        'bayer-rewards-calculator.results.breakdown-table.header.acres'
                      )}
                    >
                      {selectedProduct.acres}
                    </td>
                    <td
                      className={styles['table__data-cell']}
                      data-label={t(
                        'bayer-rewards-calculator.results.breakdown-table.header.estimated-cost'
                      )}
                    >
                      {t('format.price', {
                        value: selectedProduct.acres * selectedProduct.pricePerAcre,
                      })}
                    </td>
                  </tr>
                ))}
              <tr className={cx(styles['table__data-row'])}>
                <td
                  colSpan={4}
                  className={cx(styles['table__data-cell'], styles['table__bottom-header'])}
                >
                  {t('bayer-rewards-calculator.results.breakdown-table.total-cost')}
                </td>
                <td className={cx(styles['table__data-cell'], styles['table__bottom-header'])}>
                  {t('format.price', {
                    value: totalCost,
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {Object.entries(breakdown).map(([rewardKey, details]) =>
          renderRewardSection(rewardKey, details)
        )}
      </div>
    </StepsWrapper>
  );
};

Results.displayName = 'Results';

export default Results;
