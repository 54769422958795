export const silentLogger = {
  debug: () => {},
  info: () => {},
  warn: () => {},
  error: () => {},
};

export const enum FlagKeys {
  DATA_VIS = 'ca-my-bayer-data-visualization',
  EVENT_PASS = 'ca-my-bayer-outdoor-show-pass',
  SUMMER_SIZZLER = 'ca-my-bayer-summer-sizzler-banner',
  CALCULATOR_AD = 'ca-my-bayer-calculator-ad',
  CALCULATOR_PAGE = 'ca-my-bayer-bayer-rewards-calculator',
}
