import { CalculatorPreferencesForm, PreFillOption } from '../../../../lib/types/calculator';
import { FC, useEffect, useId } from 'react';
import { CalculatorContextActionTypes } from '../../../../lib/contexts/calculator/CalculatorContext.types';
import Checkbox from '../../../../components/_shared/Checkbox';
import { cropList } from '../../../../lib/constants/calculator';
import cx from 'classnames';
// import Radio from '../../../../components/_shared/Radio';
import { type StepComponentProps } from '../../../../lib/constants/stepper';
import StepsWrapper from '../../../Onboarding/StepsWrapper';
import styles from '../../BayerRewardsCalculator.module.scss';
import useCalculatorContext from '../../../../lib/contexts/calculator/useCalculatorContext';
import { /* Controller, */ useForm } from 'react-hook-form';
import { usePreferredRegion } from '../../../../lib/hooks/usePreferredRegion';
import { useTranslation } from 'react-i18next';

const Preferences: FC<StepComponentProps> = ({ back, next }: StepComponentProps) => {
  const formId = useId();
  const { t } = useTranslation();
  const [preferences, dispatch] = useCalculatorContext();
  const { programRegion, isLoading: isRegionLoading } = usePreferredRegion();

  const {
    // control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
    getValues,
  } = useForm<CalculatorPreferencesForm>({
    defaultValues: {
      preFillForm: PreFillOption.NO,
    },
  });

  useEffect(() => {
    if (!isRegionLoading) {
      dispatch({
        type: CalculatorContextActionTypes.UpdateProgramRegion,
        payload: programRegion,
      });
    }
  }, [isRegionLoading]);

  useEffect(() => {
    if (preferences.preFill && preferences.selectedCrops) {
      reset({
        preFillForm: preferences.preFill,
        crops: preferences.selectedCrops,
      });
    }
  }, []);

  const renderCheckboxes = () =>
    cropList(t).map((crop) => (
      <li key={crop.value}>
        <Checkbox
          {...register('crops', {
            required: t('form.errors.required'),
          })}
          label={crop.label}
          value={crop.value}
          image={crop.image}
          hasError={!!errors.crops}
        />
      </li>
    ));

  const onSubmit = () => {
    const formValues = getValues();

    dispatch({
      type: CalculatorContextActionTypes.UpdatePreferences,
      payload: {
        prefill: formValues.preFillForm,
        selectedCrops: formValues.crops,
      },
    });

    scrollTo({ top: 0, behavior: 'smooth' });
    next?.();
  };

  return (
    <StepsWrapper
      title={t('bayer-rewards-calculator.steps.preferences')}
      subTitle={t('bayer-rewards-calculator.preferences.description')}
      back={{ text: t('general.back'), onClick: back }}
      submit={{
        text: t('general.next'),
        buttonFormId: formId,
        buttonType: 'submit',
      }}
      classNames={{
        wrapper: cx(styles['calculator__step-wrapper']),
        form: cx(styles['calculator__form-wrapper']),
      }}
    >
      <hr className={cx(styles['calculator__divider'])} />
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        {/*
        <div className={cx(styles['calculator__section'])}>
          <h2 className={cx(styles['calculator__sub-title'])}>
            {t('bayer-rewards-calculator.preferences.prefill.heading')}
          </h2>
          <p className={cx(styles['calculator__text'])}>
            {t('bayer-rewards-calculator.preferences.prefill.description')}
          </p>
          <Controller
            control={control}
            name="preFillForm"
            rules={{ required: t('form.errors.required') }}
            render={({ field: { onChange, name, value } }) => (
              <div>
                <Radio
                  className={cx(styles['filter-modal__radio'])}
                  label={t('general.yes')}
                  value={PreFillOption.YES}
                  checked={value === PreFillOption.YES}
                  name={name}
                  onChange={onChange}
                />
                <Radio
                  className={cx(styles['filter-modal__radio'])}
                  label={t('general.no')}
                  value={PreFillOption.NO}
                  checked={value === PreFillOption.NO}
                  name={name}
                  onChange={onChange}
                />
              </div>
            )}
          />
        </div>
        <hr className={cx(styles['calculator__divider'])} />
        */}
        <div className={cx(styles['calculator__section'])}>
          <h2 className={cx(styles['calculator__sub-title'])}>
            {t('bayer-rewards-calculator.preferences.select-crops.heading')}
          </h2>
          <p className={cx(styles['calculator__text'])}>
            {t('bayer-rewards-calculator.preferences.select-crops.description')}
          </p>
          <ul className={cx(styles['calculator__crop-list'])}>{renderCheckboxes()}</ul>
          {errors.crops && (
            <p className={cx(styles['calculator__error-message'])}>{errors.crops.message}</p>
          )}
        </div>
      </form>
    </StepsWrapper>
  );
};

Preferences.displayName = 'Preferences';

export default Preferences;
