import { CalculatorProduct, ProductQualification } from '../../__generated__/graphql';
import { Color } from '../constants/charts';

export const ResultsChartColor = [
  Color.PINK,
  Color.GREEN,
  Color.DARK_BLUE,
  Color.ORANGE,
  Color.PURPLE,
  Color.RED,
];

export enum ProductMainGroup {
  FUNGICIDES = 'Fungicides',
  HERBICIDES = 'Herbicides',
  INSECTICIDE = 'Insecticide',
  SEED_TREATMENT = 'Seed Treatment',
  TRAIT = 'Trait',
}

export enum PreFillOption {
  YES = 'yes',
  NO = 'no',
}
export interface CalculatorPreferencesForm {
  preFillForm: PreFillOption;
  crops: string[];
}

export interface CalculatorCategory {
  key: ProductMainGroup;
  title: string;
  subtitle: string;
}

export interface ProductGroup {
  group: string;
  options: ProductGroupOption[];
}
export interface ProductGroupOption {
  label: string;
  value: string;
  product: CalculatorProduct;
}

export interface SelectedProduct {
  group: string;
  crop: string;
  sku: string;
  acres: number;
  pricePerAcre: number;
  name: string;
}

export interface RewardsDetails {
  reward: number;
  products: SelectedProduct[];
  programUrl?: string;
}

export interface RewardsBreakdown {
  [key: string]: RewardsDetails;
}

export interface CalculationRule {
  id: string; // Unique identifier for the rule
  condition: (
    products: SelectedProduct[],
    segmentQualification: ProductQualification,
    qualyfingAcres: QualyfingAcres
  ) => boolean; // Function to check if the rule applies
  calculate: (
    products: SelectedProduct[],
    segmentQualification: ProductQualification,
    qualyfingAcres: QualyfingAcres
  ) => RewardsBreakdown; // Function to calculate the reward and return it in the RewardsBreakdown format
}

export interface QualyfingAcres {
  herbicides: number;
  insecticides: number;
  fungicides: number;
  seedTreatment: number;
}
