import {
  CalculatorContextAction,
  CalculatorContextActionTypes,
  CalculatorContextState,
} from './CalculatorContext.types';
import { updateRewardsAndCost } from '../../utils/calculator';

const CalculatorContextReducer = (
  state: CalculatorContextState,
  action: CalculatorContextAction
): CalculatorContextState => {
  switch (action.type) {
    case CalculatorContextActionTypes.UpdatePreferences:
      return {
        ...state,
        preFill: action.payload.prefill,
        selectedCrops: action.payload.selectedCrops,
      };
    case CalculatorContextActionTypes.AddSelectedProduct:
      return updateRewardsAndCost({
        ...state,
        selectedProducts: [...state.selectedProducts, action.payload],
      });
    case CalculatorContextActionTypes.UpdateSelectedProduct:
      return updateRewardsAndCost({
        ...state,
        selectedProducts: state.selectedProducts.map((product, idx) =>
          idx === action.payload.index ? { ...product, ...action.payload.product } : product
        ),
      });
    case CalculatorContextActionTypes.RemoveSelectedProduct:
      return updateRewardsAndCost({
        ...state,
        selectedProducts: state.selectedProducts.filter((_, idx) => idx !== action.payload.index),
      });
    case CalculatorContextActionTypes.UpdateProductQualifications:
      return {
        ...state,
        productQualifications: action.payload.productQualifications,
      };
    case CalculatorContextActionTypes.ResetSelectedProduct:
      return {
        ...state,
        totalCost: 0,
        totalRewards: 0,
        selectedProducts: [],
      };
    case CalculatorContextActionTypes.UpdateProgramRegion:
      return {
        ...state,
        region: action.payload,
      };
    default:
      return state;
  }
};

export default CalculatorContextReducer;
