import {
  ProductGroup,
  ProductMainGroup,
  QualyfingAcres,
  RewardsBreakdown,
  SelectedProduct,
} from '../types/calculator';
import { calculationRules as calculationRulesEast } from '../../pages/BayerRewardsCalculator/rules/east';
import { calculationRules as calculationRulesWest } from '../../pages/BayerRewardsCalculator/rules/west';

import { CalculatorContextState } from '../contexts/calculator/CalculatorContext.types';
import { CalculatorProducts } from '../../__generated__/graphql';
import { Region } from '../constants/pre-orders';

export const transformProductsByGroup = (
  calculatorProducts: CalculatorProducts[]
): ProductGroup[] =>
  calculatorProducts.map((group) => ({
    group: group.group,
    options: group.products.map((product) => ({
      label: product.name,
      value: product.sku,
      product,
    })),
  }));

export const calculateQualifyingAcres = (
  products: SelectedProduct[],
  qualifierProducts: string[],
  doubleQualifierProducts: string[]
): QualyfingAcres => {
  const { herbicides, fungicides, seedTreatment, insecticides } = products.reduce(
    (totals, product) => {
      let multiplier = 0;

      if (doubleQualifierProducts.includes(product.sku)) {
        multiplier = 2;
      } else if (qualifierProducts.includes(product.sku)) {
        multiplier = 1;
      }

      if (multiplier > 0) {
        return {
          ...totals,
          herbicides:
            product.group === ProductMainGroup.HERBICIDES
              ? totals.herbicides + product.acres * multiplier
              : totals.herbicides,
          fungicides:
            product.group === ProductMainGroup.FUNGICIDES
              ? totals.fungicides + product.acres * multiplier
              : totals.fungicides,
          seedTreatment:
            product.group === ProductMainGroup.SEED_TREATMENT
              ? totals.seedTreatment + product.acres * multiplier
              : totals.seedTreatment,
          insecticides:
            product.group === ProductMainGroup.INSECTICIDE
              ? totals.insecticides + product.acres * multiplier
              : totals.insecticides,
        };
      }

      return totals;
    },
    { herbicides: 0, fungicides: 0, seedTreatment: 0, insecticides: 0 }
  );

  return { herbicides, fungicides, seedTreatment, insecticides };
};

export const updateRewardsAndCost = (state: CalculatorContextState): CalculatorContextState => {
  const calculationRules =
    state.region === Region.EAST ? calculationRulesEast : calculationRulesWest;
  const runningTotal = state.selectedProducts.reduce(
    (sum, product) => sum + product.acres * product.pricePerAcre,
    0
  );

  const rewardsBreakdown: RewardsBreakdown = {};
  const estimatedRewards = calculationRules.reduce((rewardSum, rule) => {
    const segmentQualification = state.productQualifications.find(
      (qualification) => qualification.segment === rule.id
    );

    const qualifyingAcres = calculateQualifyingAcres(
      state.selectedProducts,
      segmentQualification?.qualifierProducts || [],
      segmentQualification?.doubleQualifierProducts || []
    );

    if (
      segmentQualification &&
      rule.condition(state.selectedProducts, segmentQualification, qualifyingAcres)
    ) {
      const ruleBreakdown = rule.calculate(
        state.selectedProducts,
        segmentQualification,
        qualifyingAcres
      );

      Object.entries(ruleBreakdown).forEach(([type, details]) => {
        if (!rewardsBreakdown[type]) {
          rewardsBreakdown[type] = {
            reward: 0,
            products: [],
          };
        }

        rewardsBreakdown[type].reward += details.reward;
        rewardsBreakdown[type].products.push(...details.products);
        rewardsBreakdown[type].programUrl = details.programUrl;
      });

      return rewardSum + Object.values(ruleBreakdown).reduce((sum, item) => sum + item.reward, 0);
    }
    return rewardSum;
  }, 0);

  return {
    ...state,
    totalCost: runningTotal,
    totalRewards: estimatedRewards,
    breakdown: rewardsBreakdown,
  };
};
